import React, { useEffect, useState, useMemo, useRef } from "react";
import { track } from "@vercel/analytics";
import cx from "classnames";
import moment from "moment";
import { MdRotateRight } from "react-icons/md";
import { IoMdTrophy, IoMdGlobe, IoMdSearch, IoMdPeople } from "react-icons/io";
import {
  TableBarChart,
  TablePlaceholder,
  V1V2Bar,
} from "../components/TopAffiliates";
import GenericChart from "../components/GenericChart";
import {
  MemoizedBubbleChartTopAffiliates,
  MemoizedBubbleChartTopReferrals,
} from "../components/BubbleChart";
import {
  yaxisFormatterNumber,
  tooltipLabelFormatterUnits,
  tooltipFormatterNumber,
  formatNumber,
  COLORS,
} from "../helpers";
import {
  calculateAffiliateGlobalStats,
  calculateStats,
  formatAffiliateStats,
  aggregateDataByWeek,
  aggregateDataByMonth,
  isValidEthereumAddress,
  utf8ToHex,
  hexToUtf8,
} from "../utils";
import {
  fetchGraphData,
  getTopAffiliates,
  getTopReferrals,
  useReferralsData,
  useLastSubgraphBlock,
  useLastBlock,
  createAffiliateTierQuery,
  createGlobalAffiliateStatsQuery,
  createDailyAffiliateStatsQuery,
} from "../dataProvider";

const NOW = Math.floor(Date.now() / 1000);
const N_TOP_AFFILIATES = 15;

function Avalanche(props) {
  const chainName = "avalanche";
  const subgraph =
    chainName === "arbitrum"
      ? "gmx-arbitrum-referrals"
      : "gmx-avalanche-referrals";

  // UseStates
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  const [selectedInterval, setSelectedInterval] = useState("monthly");
  const [selectedSearchInterval, setSelectedSearchInterval] =
    useState("monthly");
  const [topReferralsData, setTopReferralsData] = useState([]);
  const [topReferralsLoading, setTopReferralsLoading] = useState(false);

  const [topAffiliatesData, setTopAffiliatesData] = useState([]);
  const [topAffiliatesLoading, setTopAffiliatesLoading] = useState(false);
  const [searchAffiliatesGlobalData, setSearchAffiliatesGlobalData] =
    useState(null);
  const [searchAffiliatesLoading, setSearchAffiliatesLoading] = useState(false);
  const [searchAffiliatesError, setSearchAffiliatesError] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [searchAffiliateId, setSearchAffiliateId] = useState(null);
  const [searchAffiliateIdData, setSearchAffiliateIdData] = useState(null);
  const [affiliateStat, setAffiliateStat] = useState(null);
  const [affiliateGlobalStats, setAffiliateGlobalStats] = useState(null);

  // UseRefs
  const searchAffiliateInputRef = useRef(null);
  const graphsRef = useRef(null);

  const [lastSubgraphBlock] = useLastSubgraphBlock();
  const [lastBlock] = useLastBlock();
  const isObsolete =
    lastSubgraphBlock &&
    lastBlock &&
    lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600;

  const [
    referralsDataDaily,
    referralsDataWeekly,
    referralsDataMonthly,
    referralsLoading,
  ] = useReferralsData({
    from: fromValue ? +new Date(fromValue) / 1000 : undefined,
    to: toValue ? +new Date(toValue) / 1000 : NOW,
    chainName: chainName,
  });

  const stats = useMemo(() => {
    return {
      daily: calculateStats(referralsDataDaily),
      weekly: calculateStats(referralsDataWeekly),
      monthly: calculateStats(referralsDataMonthly),
    };
  }, [referralsDataDaily, referralsDataWeekly, referralsDataMonthly]);

  const handleSearchAffiliates = () => {
    const searchAffiliateInput = searchAffiliateInputRef.current?.value;
    if (searchAffiliateInput) {
      track("Search", { input: searchAffiliateInput });
      setSearchAffiliatesLoading(true);
      const inputIsEthAddress = isValidEthereumAddress(searchAffiliateInput);
      let query;
      if (inputIsEthAddress) {
        const affiliate = searchAffiliateInput.toLowerCase();
        query = createGlobalAffiliateStatsQuery(null, affiliate);
      } else {
        const referralCodeHex = utf8ToHex(searchAffiliateInput);
        query = createGlobalAffiliateStatsQuery(referralCodeHex, null);
      }

      fetchGraphData(query, { subgraph })
        .then((data) => {
          if (data.affiliateStats.length === 0) {
          } else {
            setSearchAffiliatesGlobalData(data.affiliateStats);
            setHasSubmitted(true);

            graphsRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
            });
          }
        })
        .catch((error) => {
          setSearchAffiliatesError(error);
        })
        .finally(() => {});
    }
  };

  useEffect(() => {
    if (searchAffiliatesGlobalData && searchAffiliatesGlobalData.length > 0) {
      let query;
      const affiliate = searchAffiliatesGlobalData[0].affiliate;
      const referralCode = searchAffiliatesGlobalData[0].referralCode;
      if (searchAffiliatesGlobalData.length > 1) {
        // More than one referralCode found
        query = createDailyAffiliateStatsQuery(null, affiliate);
      } else {
        // Just one referralCode found
        query = createDailyAffiliateStatsQuery(referralCode, null);
      }
      fetchGraphData(query, { subgraph })
        .then((graphData) => {
          if (graphData && Array.isArray(graphData.affiliateStats)) {
            const [
              formattedDataDaily,
              formattedDataWeekly,
              formattedDataMonthly,
            ] = formatAffiliateStats(graphData);
            const formattedData = [];
            formattedData.daily = formattedDataDaily;
            formattedData.weekly = formattedDataWeekly;
            formattedData.monthly = formattedDataMonthly;
            setAffiliateStat(formattedData);
          } else {
            console.error(
              "Invalid structure for affiliateStats: ",
              graphData.affiliateStats
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching affiliate data: ", error);
        })
        .finally(() => {
          setSearchAffiliatesLoading(false);
          setSearchAffiliateId(affiliate);
        });
    }
  }, [searchAffiliatesGlobalData]);

  // UseEffects
  useEffect(() => {
    if (searchAffiliateId) {
      const query = createAffiliateTierQuery(searchAffiliateId);
      fetchGraphData(query, { subgraph })
        .then((data) => {
          setSearchAffiliateIdData(data.affiliate);

          graphsRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        })
        .catch((error) => {
          setSearchAffiliatesError(error);
        })
        .finally(() => {});
    }
  }, [searchAffiliateId]);

  useEffect(() => {
    setTopReferralsLoading(true);
    getTopReferrals({ n: N_TOP_AFFILIATES, chainName: chainName })
      .then((data) => {
        // Create an array of promises
        const promises = data.map((item) => {
          const referral = item.referral;
          const query = `{
            traderToReferralCode(id: "${referral}") {
              referralCode {
                id
                code
                owner
              }
            }
          }`;
          return fetchGraphData(query, { subgraph })
            .then((data) => {
              const referralCodeHex =
                data.traderToReferralCode.referralCode.code;
              const referralCode = hexToUtf8(referralCodeHex);
              const affiliate = data.traderToReferralCode.referralCode.owner;
              return {
                ...item,
                affiliate,
                referralCode,
                referralCodeHex,
              };
            })
            .catch((error) => {
              console.error("Error in fetchGraphData:", error);
              // Return the original item in case of an error
              return item;
            });
        });
        // Wait for all promises to resolve
        Promise.all(promises)
          .then((newData) => {
            setTopReferralsData(newData);
          })
          .catch((error) => {
            console.error("Error in Promise.all:", error);
          })
          .finally(() => {
            setTopReferralsLoading(false);
          });
      })
      .catch((error) => {
        console.error("Error fetching top referrals:", error);
      });
  }, []);

  useEffect(() => {
    setTopAffiliatesLoading(true);
    getTopAffiliates({ n: N_TOP_AFFILIATES, chainName: chainName })
      .then((data) => {
        setTopAffiliatesData(data);
      })
      .catch((error) => {
        console.error("Error fetching top affiliates:", error);
      })
      .finally(() => {
        setTopAffiliatesLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!affiliateStat) {
      setAffiliateGlobalStats(null);
      return;
    }
    const keys = [
      "volume",
      "volumeCumulative",
      "trades",
      "tradesCumulative",
      "tradedReferralsCount",
      "tradedReferralsCountCumulative",
      "registeredReferralsCount",
      "registeredReferralsCountCumulative",
      "registeredReferralsCountCumulativeUnique",
      "totalRebateUsd",
      "totalRebateUsdCumulative",
      "discountUsd",
      "discountUsdCumulative",
      "rewardUsd",
      "rewardUsdCumulative",
      "volumeV1",
      "volumeCumulativeV1",
      "tradesV1",
      "tradesCumulativeV1",
      "totalRebateUsdV1",
      "totalRebateUsdCumulativeV1",
      "discountUsdV1",
      "discountUsdCumulativeV1",
      "rewardUsdV1",
      "rewardUsdCumulativeV1",
      "volumeV2",
      "volumeCumulativeV2",
      "tradesV2",
      "tradesCumulativeV2",
      "totalRebateUsdV2",
      "totalRebateUsdCumulativeV2",
      "discountUsdV2",
      "discountUsdCumulativeV2",
      "rewardUsdV2",
      "rewardUsdCumulativeV2",
    ];
    const dailyStats = calculateAffiliateGlobalStats(affiliateStat.daily);
    const weeklyStats = calculateAffiliateGlobalStats(
      aggregateDataByWeek(affiliateStat.weekly, keys)
    );
    const monthlyStats = calculateAffiliateGlobalStats(
      aggregateDataByMonth(affiliateStat.monthly, keys)
    );
    setAffiliateGlobalStats({
      daily: dailyStats,
      weekly: weeklyStats,
      monthly: monthlyStats,
    });
  }, [affiliateStat]);

  const currentData =
    selectedInterval === "daily"
      ? referralsDataDaily
      : selectedInterval === "weekly"
      ? referralsDataWeekly
      : referralsDataMonthly;
  const chartData = currentData ? Object.values(currentData) : [];
  const currentSearchData =
    selectedSearchInterval === "daily"
      ? affiliateStat?.daily
      : selectedSearchInterval === "weekly"
      ? affiliateStat?.weekly
      : affiliateStat?.monthly;
  const totalColor = props.mode === "dark" ? "WHITE" : "BLACK";

  return (
    <div className="Home">
      {/*======================Global Analytics========================*/}
      <div className="Home relative-container">
        {lastSubgraphBlock && lastBlock && (
          <p
            className={cx("page-description top-right-description", {
              warning: isObsolete,
            })}
            style={{ marginTop: "-1.5rem" }}
          >
            {isObsolete && "Data is obsolete. "}
            Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
            &nbsp;at block{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://arbiscan.io/block/${lastSubgraphBlock.number}`}
            >
              {lastSubgraphBlock.number}
            </a>
          </p>
        )}
      </div>
      <div>
        <h1 style={{ marginTop: "1rem" }}>
          <span>
            <IoMdGlobe />
          </span>{" "}
          <span>Global Analytics</span>
        </h1>

        <div className="chart-grid">
          <StatCell
            label="Volume"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totalVolume"
            deltaKey="totalVolumeDelta"
            currency={true}
            compact={false}
          />
          <StatCell
            label="Trades"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totaltradesCount"
            deltaKey="totaltradesCountDelta"
            currency={false}
            compact={false}
          />
          <StatCell
            label="Referrals"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totalReferralsCount"
            deltaKey="totalReferralsCountDelta"
            currency={false}
            compact={false}
          />
          <StatCell
            label="Affiliates"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totalAffiliatesCount"
            deltaKey="totalAffiliatesCountDelta"
            currency={false}
            compact={false}
          />
          <StatCell
            label="Discounts"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totalDiscountUsd"
            deltaKey="totalDiscountUsdDelta"
            currency={true}
            compact={false}
          />
          <StatCell
            label="Rewards"
            stats={stats}
            selectedInterval={selectedInterval}
            statKey="totalAffiliateRebateUsd"
            deltaKey="totalAffiliateRebateUsdDelta"
            currency={true}
            compact={false}
          />
        </div>

        <div className="interval-controls">
          <button
            id="monthly"
            className={`toggle-button ${
              selectedInterval === "monthly" ? "active" : ""
            }`}
            onClick={() => setSelectedInterval("monthly")}
          >
            1M
          </button>
          <button
            id="weekly"
            className={`toggle-button ${
              selectedInterval === "weekly" ? "active" : ""
            }`}
            onClick={() => setSelectedInterval("weekly")}
          >
            1W
          </button>
          <button
            id="daily"
            className={`toggle-button ${
              selectedInterval === "daily" ? "active" : ""
            }`}
            onClick={() => setSelectedInterval("daily")}
          >
            1D
          </button>
        </div>

        <div className="chart-grid">
          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={referralsLoading}
              title="Volume"
              data={chartData}
              yaxisDataKey="volume"
              rightYaxisDataKey="volumeCumulative"
              yaxisTickFormatter={yaxisFormatterNumber}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                {
                  key: "volume",
                  name: selectedInterval,
                  color: COLORS[1],
                  unit: "$",
                },
                {
                  key: "volumeCumulative",
                  name: "total",
                  type: "Line",
                  yAxisId: "right",
                  strokeWidth: 1,
                  color: totalColor,
                  unit: "$",
                },
              ]}
              type="Composed"
            />
          </div>

          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={referralsLoading}
              title="Trades"
              data={chartData}
              yaxisDataKey="trades"
              rightYaxisDataKey="tradesCumulative"
              yaxisTickFormatter={yaxisFormatterNumber}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                {
                  key: "trades",
                  type: "Bar",
                  name: selectedInterval,
                  color: COLORS[10],
                },
                {
                  key: "tradesCumulative",
                  strokeWidth: 1,
                  yAxisId: "right",
                  type: "Line",
                  name: "total",
                  color: totalColor,
                },
              ]}
              type="Composed"
            />
          </div>
          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={referralsLoading}
              title="Referrals and Affiliates"
              data={chartData}
              yaxisDataKey="totalReferralAffiliate"
              yaxisTickFormatter={yaxisFormatterNumber}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                { key: "referralsCount", name: "referrals", color: COLORS[12] },
                {
                  key: "referralCodesCount",
                  name: "affiliates",
                  color: COLORS[11],
                },
              ]}
              type="Composed"
            />
          </div>
          <div className="chart-cell">
            <GenericChart
              syncId="syncGlp"
              loading={referralsLoading}
              title="Discounts & Rewards"
              data={chartData}
              yaxisDataKey="totalRebateUsd"
              yaxisTickFormatter={yaxisFormatterNumber}
              tooltipFormatter={tooltipFormatterNumber}
              tooltipLabelFormatter={tooltipLabelFormatterUnits}
              items={[
                {
                  key: "discountUsd",
                  name: "discount",
                  color: COLORS[2],
                  unit: "$",
                },
                {
                  key: "affiliateRebateUsd",
                  name: "reward",
                  color: COLORS[7],
                  unit: "$",
                },
              ]}
              type="Composed"
            />
          </div>
        </div>
      </div>

      {/*======================Top Affiliates========================*/}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ marginTop: "2rem" }}>
          {" "}
          <span>
            <IoMdTrophy />
          </span>{" "}
          <span>Top Affilates</span>
        </h1>
        {topAffiliatesLoading && (
          <MdRotateRight
            size="2em"
            className="loader"
            style={{ marginLeft: "10px", marginTop: "1.5rem" }}
          />
        )}
      </div>
      <h4 style={{ marginBottom: "-0.3rem", marginTop: "-0.5rem" }}>
        by total volume
      </h4>
      <div className="chart-grid">
        {topAffiliatesLoading ? (
          <>
            <TablePlaceholder />
          </>
        ) : (
          <div className="referral-table">
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Affiliate</th>
                  <th>Referrals</th>
                  <th>Volume ($)</th>
                  <th>Trades</th>
                  <th>Reward ($)</th>
                </tr>
              </thead>
              <tbody>
                {topAffiliatesData.map((affiliate, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{affiliate.referralCode}</td>
                    <td>{affiliate.registeredReferralsCount}</td>
                    <td>
                      <TableBarChart
                        label1="v1"
                        value1={affiliate.volumeV1}
                        label2="v2"
                        value2={affiliate.volumeV2}
                        color1={COLORS[13]}
                        color2={COLORS[3]}
                      />
                    </td>
                    <td>
                      <TableBarChart
                        label1="v1"
                        value1={parseInt(affiliate.tradesV1)}
                        label2="v2"
                        value2={parseInt(affiliate.tradesV2)}
                        color1={COLORS[13]}
                        color2={COLORS[3]}
                      />
                    </td>
                    <td>
                      <TableBarChart
                        label1="v1"
                        value1={affiliate.rewardUsdV1}
                        label2="v2"
                        value2={affiliate.rewardUsdV2}
                        color1={COLORS[13]}
                        color2={COLORS[3]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {topAffiliatesLoading ? (
          <></>
        ) : (
          <div className="referral-table">
            {topAffiliatesData && (
              <MemoizedBubbleChartTopAffiliates data={topAffiliatesData} />
            )}
          </div>
        )}
      </div>
      {/*======================Top Referrals========================*/}
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ marginTop: "2rem" }}>
          {" "}
          <span>
            <IoMdPeople />
          </span>{" "}
          <span>Top Referrals</span>
        </h1>
        {topReferralsLoading && (
          <MdRotateRight
            size="2em"
            className="loader"
            style={{ marginLeft: "10px", marginTop: "1.5rem" }}
          />
        )}
      </div>
      <h4 style={{ marginBottom: "-0.3rem", marginTop: "-0.5rem" }}>
        by total volume
      </h4>

      <div className="chart-grid">
        {topReferralsLoading ? (
          <>
            <TablePlaceholder />
          </>
        ) : (
          <div className="referral-table">
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Referral</th>
                  <th>Affiliate</th>
                  <th>Volume ($)</th>
                  <th>Discount ($)</th>
                </tr>
              </thead>
              <tbody>
                {topReferralsData.map((referral, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{referral.referral}</td>
                    <td>{referral.referralCode}</td>
                    <td>
                      <TableBarChart
                        label1="v1"
                        value1={referral.volumeV1}
                        label2="v2"
                        value2={referral.volumeV2}
                        color1={COLORS[13]}
                        color2={COLORS[3]}
                      />
                    </td>
                    <td>
                      <TableBarChart
                        label1="v1"
                        value1={referral.discountUsdV1}
                        label2="v2"
                        value2={referral.discountUsdV2}
                        color1={COLORS[13]}
                        color2={COLORS[3]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {topReferralsLoading ? (
          <></>
        ) : (
          <div className="referral-table">
            {topReferralsData && (
              <MemoizedBubbleChartTopReferrals data={topReferralsData} />
            )}
          </div>
        )}
      </div>

      {/*=====================Search Affiliate==========================*/}
      <h1 style={{ marginTop: "2rem" }}>
        <span>
          <IoMdSearch />
        </span>{" "}
        <span>Search Affiliate</span>
      </h1>
      <div className="chart-grid">
        <div className="chart-cell search">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="0x or referral code"
              ref={searchAffiliateInputRef}
            />
            <button onClick={handleSearchAffiliates} className="submit-button">
              Submit
            </button>
          </div>
        </div>
        {searchAffiliatesError ? (
          <div style={{ color: "red" }}>Invalid code.</div>
        ) : null}
        {hasSubmitted && (
          <div className="chart-cell search tier">
            {searchAffiliateIdData && (
              <>
                <div>Affiliate:</div>
                <div>
                  <a
                    href={`https://debank.com/profile/${searchAffiliateIdData.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {searchAffiliateIdData.id}
                  </a>
                </div>
              </>
            )}
          </div>
        )}
        {hasSubmitted && (
          <div className="chart-cell search tier">
            {searchAffiliateIdData && affiliateGlobalStats?.weekly && (
              <>
                <div>Tier: {parseInt(searchAffiliateIdData.tierId) + 1}</div>
                <div>
                  Avg. weekly volume:{" "}
                  {formatNumber(
                    affiliateGlobalStats.weekly.totalVolume /
                      affiliateGlobalStats.weekly.length,
                    {
                      currency: true,
                      compact: true,
                    }
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <div className="chart-grid">
        {hasSubmitted && (
          <>
            <StatCell
              type="chart-cell search stats"
              label="Volume"
              stats={affiliateGlobalStats}
              selectedInterval={selectedSearchInterval}
              statKey="totalVolume"
              deltaKey="totalVolumeDelta"
              currency={true}
              compact={false}
              loading={searchAffiliatesLoading}
            />
            <StatCell
              type="chart-cell search stats"
              label="Trades"
              stats={affiliateGlobalStats}
              selectedInterval={selectedSearchInterval}
              statKey="totalTrades"
              deltaKey="totalTradesDelta"
              currency={false}
              compact={false}
              loading={searchAffiliatesLoading}
            />
            <StatCell
              type="chart-cell search stats"
              label="Referrals"
              stats={affiliateGlobalStats}
              selectedInterval={selectedSearchInterval}
              statKey="totalReferrals"
              deltaKey="totalReferralsDelta"
              currency={false}
              compact={false}
              loading={searchAffiliatesLoading}
            />
            <StatCell
              type="chart-cell search stats"
              label="Reward"
              stats={affiliateGlobalStats}
              selectedInterval={selectedSearchInterval}
              statKey="totalRewardUsd"
              deltaKey="totalRewardUsdDelta"
              currency={true}
              compact={false}
              loading={searchAffiliatesLoading}
            />
            <div className="chart-cell">
              <V1V2Bar
                name="← volume →"
                label1="v1"
                value1={affiliateGlobalStats?.daily.totalVolume ?? 0}
                label2="v2"
                value2={affiliateGlobalStats?.daily.totalVolumeV2 ?? 0}
                color1={COLORS[13]}
                color2={COLORS[3]}
                currency={true}
                compact={true}
                loading={!affiliateGlobalStats}
              />
            </div>
            <div className="chart-cell">
              <V1V2Bar
                name="← trades →"
                label1="v1"
                value1={affiliateGlobalStats?.daily.totalTrades ?? 0}
                label2="v2"
                value2={affiliateGlobalStats?.daily.totalTradesV2 ?? 0}
                color1={COLORS[13]}
                color2={COLORS[3]}
                currency={false}
                compact={true}
                loading={!affiliateGlobalStats}
              />
            </div>
            <div className="chart-cell">
              <V1V2Bar
                name="← referrals →"
                label1="v1+v2"
                value1={affiliateGlobalStats?.daily.totalReferrals ?? 0}
                label2=""
                value2={0}
                color1={COLORS[13]}
                color2={COLORS[3]}
                currency={false}
                compact={true}
                loading={!affiliateGlobalStats}
              />
            </div>
            <div className="chart-cell">
              <V1V2Bar
                name="← reward →"
                label1="v1"
                value1={affiliateGlobalStats?.daily.totalRewardUsd ?? 0}
                label2="v2"
                value2={affiliateGlobalStats?.daily.totalRewardV2 ?? 0}
                color1={COLORS[13]}
                color2={COLORS[3]}
                currency={true}
                compact={true}
                loading={!affiliateGlobalStats}
              />
            </div>
          </>
        )}
      </div>
      {hasSubmitted && (
        <div className="interval-controls">
          <button
            id="monthly"
            className={`toggle-button ${
              selectedSearchInterval === "monthly" ? "active" : ""
            }`}
            onClick={() => setSelectedSearchInterval("monthly")}
          >
            1M
          </button>
          <button
            id="weekly"
            className={`toggle-button ${
              selectedSearchInterval === "weekly" ? "active" : ""
            }`}
            onClick={() => setSelectedSearchInterval("weekly")}
          >
            1W
          </button>
          <button
            id="daily"
            className={`toggle-button ${
              selectedSearchInterval === "daily" ? "active" : ""
            }`}
            onClick={() => setSelectedSearchInterval("daily")}
          >
            1D
          </button>
        </div>
      )}
      <div className="chart-grid" ref={graphsRef}>
        {hasSubmitted && (
          <>
            <div className="chart-cell">
              <GenericChart
                syncId="syncIdForTrades"
                loading={searchAffiliatesLoading}
                title="Volume"
                data={currentSearchData}
                yaxisDataKey="volume"
                rightYaxisDataKey="volumeCumulative"
                yaxisTickFormatter={yaxisFormatterNumber}
                tooltipFormatter={tooltipFormatterNumber}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  {
                    key: "volumeV1",
                    name: "v1",
                    color: COLORS[13],
                    unit: "$",
                  },
                  {
                    key: "volumeV2",
                    name: "v2",
                    color: COLORS[3],
                    unit: "$",
                  },

                  {
                    key: "volumeCumulative",
                    strokeWidth: 1,
                    yAxisId: "right",
                    type: "Line",
                    name: "total",
                    unit: "$",
                    color: totalColor,
                  },
                ]}
                type="Composed"
              />
            </div>
            <div className="chart-cell">
              <GenericChart
                syncId="syncIdForTrades"
                loading={searchAffiliatesLoading}
                title="Trades"
                data={currentSearchData}
                yaxisDataKey="trades"
                rightYaxisDataKey="tradesCumulative"
                yaxisTickFormatter={yaxisFormatterNumber}
                tooltipFormatter={tooltipFormatterNumber}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  {
                    key: "tradesV1",
                    name: "v1",
                    type: "Bar",
                    color: COLORS[13],
                  },
                  {
                    key: "tradesV2",
                    name: "v2",
                    type: "Bar",
                    color: COLORS[3],
                  },
                  {
                    key: "tradesCumulative",
                    strokeWidth: 1,
                    yAxisId: "right",
                    type: "Line",
                    name: "total",
                    color: totalColor,
                  },
                ]}
                type="Composed"
              />
            </div>
            <div className="chart-cell">
              <GenericChart
                syncId="syncIdForTrades"
                loading={searchAffiliatesLoading}
                title="Referrals"
                data={currentSearchData}
                yaxisDataKey="registeredReferralsCount"
                rightYaxisDataKey="registeredReferralsCountCumulativeUnique"
                yaxisTickFormatter={yaxisFormatterNumber}
                tooltipFormatter={tooltipFormatterNumber}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  {
                    key: "registeredReferralsCount",
                    name: selectedSearchInterval,
                    type: "Bar",
                    color: COLORS[12],
                  },
                  {
                    key: "registeredReferralsCountCumulativeUnique",
                    strokeWidth: 1,
                    yAxisId: "right",
                    type: "Line",
                    name: "total",
                    color: totalColor,
                  },
                ]}
                type="Composed"
              />
            </div>
            <div className="chart-cell">
              <GenericChart
                syncId="syncIdForTrades"
                loading={searchAffiliatesLoading}
                title="Rewards"
                data={currentSearchData}
                yaxisDataKey="rewardUsdV1"
                rightYaxisDataKey="rewardUsdCumulative"
                yaxisTickFormatter={yaxisFormatterNumber}
                tooltipFormatter={tooltipFormatterNumber}
                tooltipLabelFormatter={tooltipLabelFormatterUnits}
                items={[
                  {
                    key: "rewardUsdV1",
                    name: "v1",
                    color: COLORS[13],
                    unit: "$",
                  },
                  {
                    key: "rewardUsdV2",
                    name: "v2",
                    color: COLORS[3],
                    unit: "$",
                  },
                  {
                    key: "rewardUsdCumulative",
                    strokeWidth: 1,
                    yAxisId: "right",
                    type: "Line",
                    name: "total",
                    unit: "$",
                    color: totalColor,
                  },
                ]}
                type="Composed"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function StatCell({
  type = "chart-cell stats",
  label,
  stats,
  selectedInterval,
  statKey,
  deltaKey,
  currency = true,
  compact = false,
  loading = false,
}) {
  const statData =
    stats && stats[selectedInterval] ? stats[selectedInterval] : null;
  const isDataLoading = statData === null || loading;
  const formattedValue = isDataLoading ? (
    <MdRotateRight size="2em" className="loader" />
  ) : (
    formatNumber(statData[statKey], { currency, compact })
  );

  let formattedDelta = null;
  if (!isDataLoading) {
    if (statData[deltaKey] === 0) {
      formattedDelta = "UNCH";
    } else {
      formattedDelta = formatNumber(statData[deltaKey], { currency, compact });
    }
  }

  return (
    <div className={type}>
      <div className="total-stat-label">{label}</div>
      <div className="total-stat-value">
        {formattedValue}
        {formattedDelta !== null && (
          <span className="total-stat-delta plus">
            {formattedDelta !== "UNCH" ? "\u2191 " : ""}
            {formattedDelta}
          </span>
        )}
      </div>
    </div>
  );
}

export default Avalanche;
