import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import { track } from "@vercel/analytics";
import { SpeedInsights } from "@vercel/speed-insights/react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  Redirect,
} from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import cx from "classnames";
import Arbitrum from "./views/Arbitrum";
import Avalanche from "./views/Avalanche";
import "./App.css";
import profilePic from "./img/profile.jpeg";
import logoIcon from "./img/logo_GMX.svg";
import darkLogoIcon from "./img/logo_GMX_dark.svg";
import lightLogoIcon from "./img/logo_GMX_light.svg";
import { FaSun, FaMoon, FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { RiMenuLine } from "react-icons/ri";

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div
            className="App-header-menu-icon-block"
            onClick={() => clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink
            exact
            activeClassName="active"
            className="App-header-link-main"
            to="/"
          >
            <img src={logoIcon} alt="GMX Logo" />
          </NavLink>
        </div>
      )}
      <div className="App-header-link-container">
        <NavLink
          to="/arbitrum"
          exact
          className="nav-link"
          activeClassName="active"
        >
          Arbitrum
        </NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink to="/avalanche" className="nav-link">
          Avalanche
        </NavLink>
      </div>
    </div>
  );
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode === "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode === "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  const switchMode = () => {
    const targetMode = mode === "dark" ? "light" : "dark";
    window.localStorage.setItem("mode", targetMode);
    document.querySelector("body").style.backgroundColor =
      targetMode === "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  };

  const navClick = (source) => {
    track("Nav click", { source: source });
  };

  return (
    <>
      <Router>
        <Switch>
          {mode && (
            <div className={cx("App", mode)}>
              {isDrawerVisible && (
                <AnimatePresence>
                  {isDrawerVisible && (
                    <motion.div
                      className="App-header-backdrop"
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={fadeVariants}
                      transition={{ duration: 0.2 }}
                      onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                    ></motion.div>
                  )}
                </AnimatePresence>
              )}
              <div className="nav">
                <div className="nav-left">
                  <div
                    className="App-header-menu-icon-block"
                    onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                  >
                    {!isDrawerVisible && (
                      <RiMenuLine className="App-header-menu-icon" />
                    )}
                    {isDrawerVisible && (
                      <FaTimes className="App-header-menu-icon" />
                    )}
                  </div>
                  <a
                    href="https://app.gmx.io/#/trade/?ref=kyzo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-logo"
                    onClick={() => navClick("logo")}
                  >
                    <img
                      width="87"
                      src={mode === "dark" ? darkLogoIcon : lightLogoIcon}
                      alt="GMX Logo"
                    />
                  </a>
                  <NavLink
                    to="/arbitrum"
                    exact
                    className="nav-link"
                    activeClassName="active"
                  >
                    Arbitrum
                  </NavLink>
                  <NavLink to="/avalanche" className="nav-link">
                    Avalanche
                  </NavLink>

                  <NavLink
                    to="/arbitrum"
                    exact
                    className="nav-link"
                    activeClassName="active"
                  ></NavLink>
                </div>
                <div className="nav-center">
                  <span className="nav-title referrals-dashboard">
                    REFERRALS DASHBOARD v2.0.1
                  </span>
                </div>
                <div className="nav-right">
                  <a
                    href="https://twitter.com/kyzoeth"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => navClick("twitter")}
                  >
                    <img
                      src={profilePic}
                      alt="@kyzo"
                      className="profile-pic"
                      onClick={() => navClick("pic")}
                    />
                  </a>
                  <a
                    href="https://twitter.com/kyzoeth"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link kyzo-text"
                  >
                    @KYZO
                  </a>
                  <a
                    href="https://app.gmx.io/#/trade/?ref=kyzo"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link"
                    onClick={() => navClick("app")}
                  >
                    APP
                  </a>
                  <a
                    href="https://gmxio.gitbook.io/gmx/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="nav-link docs-link"
                  >
                    DOCS
                  </a>
                  <div className="modeselect" onClick={() => switchMode()}>
                    {mode === "dark" ? <FaSun /> : <FaMoon />}
                  </div>
                </div>
              </div>
              <AnimatePresence>
                {isDrawerVisible && (
                  <motion.div
                    onClick={() => setIsDrawerVisible(false)}
                    className="App-header-links-container App-header-drawer"
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    variants={slideVariants}
                    transition={{ duration: 0.2 }}
                  >
                    <AppHeaderLinks
                      mode={mode}
                      small
                      clickCloseIcon={() => setIsDrawerVisible(false)}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="content">
                <Route path="/" exact>
                  <Redirect to="/arbitrum" />
                </Route>
                <Route
                  exact
                  path="/arbitrum"
                  render={(props) => <Arbitrum {...props} mode={mode} />}
                />
                <Route
                  exact
                  path="/avalanche"
                  render={(props) => <Avalanche {...props} mode={mode} />}
                />
              </div>
            </div>
          )}
        </Switch>
      </Router>
      <Analytics />
      <SpeedInsights />
    </>
  );
};

export default App;
