import { groupBy, forEach } from "lodash";

function createHttpError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function toReadable(ts) {
  return new Date(ts * 1000)
    .toISOString()
    .replace("T", " ")
    .replace(".000Z", "");
}

export function aggregateDataByMonth(data, keys) {
  const groupedData = groupBy(data, (item) => {
    const date = new Date(item.timestamp * 1000);
    // Set to the first day of the month
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString().split("T")[0];
  });

  const monthlyData = [];
  forEach(groupedData, (monthData, month) => {
    let monthAggregate = {
      timestamp: new Date(month).getTime() / 1000,
      startOfMonth: month,
      endOfMonth: new Date(
        new Date(month).setUTCMonth(new Date(month).getUTCMonth() + 1, 0)
      )
        .toISOString()
        .split("T")[0],
    };
    keys.forEach((key) => {
      monthAggregate[key] = 0;
    });

    monthData.forEach((day) => {
      keys.forEach((key) => {
        monthAggregate[key] += day[key];
        if (key.includes("Cumulative")) {
          monthAggregate[key] = day[key];
        }
      });
    });
    monthlyData.push(monthAggregate);
  });

  return monthlyData;
}

export function aggregateDataByWeek(data, keys) {
  const groupedData = groupBy(data, (item) => {
    const date = new Date(item.timestamp * 1000);
    const dayOfWeek = date.getUTCDay();
    const daysToLastWednesday = dayOfWeek >= 3 ? dayOfWeek - 3 : 4 + dayOfWeek;
    date.setUTCDate(date.getUTCDate() - daysToLastWednesday);
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString().split("T")[0];
  });

  const weeklyData = [];
  forEach(groupedData, (weekData, week) => {
    let weekAggregate = {
      timestamp: new Date(week).getTime() / 1000,
      startOfWeek: week,
      endOfWeek: new Date(
        new Date(week).setUTCDate(new Date(week).getUTCDate() + 6)
      )
        .toISOString()
        .split("T")[0],
    };
    keys.forEach((key) => {
      weekAggregate[key] = 0;
    });

    weekData.forEach((day) => {
      keys.forEach((key) => {
        weekAggregate[key] += day[key];
        if (key.includes("Cumulative")) {
          weekAggregate[key] = day[key];
        }
      });
    });
    weeklyData.push(weekAggregate);
  });

  return weeklyData;
}

export function aggregateSearchAffiliatesGlobalData(data) {
  const sums = {
    affiliate: data[0].affiliate,
    referralCode: [],
    numReferralCode: data.length,
    volume: 0,
    trades: 0,
    tradedReferralsCount: 0,
    registeredReferralsCount: 0,
    totalRebateUsd: 0,
    discountUsd: 0,
    rewardUsd: 0,
    v1Data: {
      volume: 0,
      trades: 0,
      totalRebateUsd: 0,
      discountUsd: 0,
      rewardUsd: 0,
    },
    v2Data: {
      volume: 0,
      trades: 0,
      totalRebateUsd: 0,
      discountUsd: 0,
      rewardUsd: 0,
    },
  };

  data.forEach((stat) => {
    sums.referralCode.push(stat.referralCode);
    sums.volume += stat.volume / 1e30;
    sums.trades += parseInt(stat.trades);
    sums.tradedReferralsCount += parseInt(stat.tradedReferralsCount);
    sums.registeredReferralsCount += parseInt(stat.registeredReferralsCount);
    sums.totalRebateUsd += stat.totalRebateUsd / 1e30;
    sums.discountUsd += stat.discountUsd / 1e30;
    sums.rewardUsd += stat.totalRebateUsd / 1e30 - stat.discountUsd / 1e30;

    sums.v1Data.volume += stat.v1Data.volume / 1e30;
    sums.v1Data.trades += parseInt(stat.v1Data.trades);
    sums.v1Data.totalRebateUsd += stat.v1Data.totalRebateUsd / 1e30;
    sums.v1Data.discountUsd += stat.v1Data.discountUsd / 1e30;
    sums.v1Data.rewardUsd +=
      stat.v1Data.totalRebateUsd / 1e30 - stat.v1Data.discountUsd / 1e30;

    sums.v2Data.volume += stat.v2Data.volume / 1e30;
    sums.v2Data.trades += parseInt(stat.v2Data.trades);
    sums.v2Data.totalRebateUsd += stat.v2Data.totalRebateUsd / 1e30;
    sums.v2Data.discountUsd += stat.v2Data.discountUsd / 1e30;
    sums.v2Data.rewardUsd +=
      stat.v2Data.totalRebateUsd / 1e30 - stat.v2Data.discountUsd / 1e30;
  });

  return sums;
}

export function formatAffiliateStats(graphData) {
  const formattedData = graphData.affiliateStats.map((item) => ({
    timestamp: parseInt(item.timestamp),
    affiliate: item.affiliate,
    referralCode: hexToUtf8(item.referralCode),
    volume: item.volume / 1e30,
    trades: parseInt(item.trades),
    tradedReferralsCount: parseInt(item.tradedReferralsCount),
    registeredReferralsCount: parseInt(item.registeredReferralsCount),
    registeredReferrals: item.registeredReferrals,
    totalRebateUsd: item.totalRebateUsd / 1e30,
    discountUsd: item.discountUsd / 1e30,
    rewardUsd: item.totalRebateUsd / 1e30 - item.discountUsd / 1e30,
    volumeV1: item.v1Data?.volume / 1e30,
    tradesV1: parseInt(item.v1Data?.trades),
    totalRebateUsdV1: item.v1Data?.totalRebateUsd / 1e30,
    discountUsdV1: item.v1Data?.discountUsd / 1e30,
    rewardUsdV1:
      item.v1Data?.totalRebateUsd / 1e30 - item.v1Data?.discountUsd / 1e30,
    volumeV2: item.v2Data?.volume / 1e30,
    tradesV2: parseInt(item.v2Data?.trades),
    totalRebateUsdV2: item.v2Data?.totalRebateUsd / 1e30,
    discountUsdV2: item.v2Data?.discountUsd / 1e30,
    rewardUsdV2:
      item.v2Data?.totalRebateUsd / 1e30 - item.v2Data?.discountUsd / 1e30,
  }));

  let volumeCumulative = 0;
  let tradesCumulative = 0;
  let tradedReferralsCountCumulative = 0;
  let registeredReferralsCountCumulative = 0;
  let registeredReferralsCumulative = [];
  let totalRebateUsdCumulative = 0;
  let discountUsdCumulative = 0;
  let rewardUsdCumulative = 0;

  let volumeCumulativeV1 = 0;
  let tradesCumulativeV1 = 0;
  let totalRebateUsdCumulativeV1 = 0;
  let discountUsdCumulativeV1 = 0;
  let rewardUsdCumulativeV1 = 0;

  let volumeCumulativeV2 = 0;
  let tradesCumulativeV2 = 0;
  let totalRebateUsdCumulativeV2 = 0;
  let discountUsdCumulativeV2 = 0;
  let rewardUsdCumulativeV2 = 0;

  const formattedDataWithCumulative = formattedData.map((item) => {
    if (item.registeredReferralsCount > 0) {
      item.registeredReferrals.forEach((referral) => {
        if (!registeredReferralsCumulative.includes(referral.referral)) {
          registeredReferralsCumulative.push(referral.referral);
        }
      });
    }
    const registeredReferralsCountCumulativeUnique =
      registeredReferralsCumulative.length;

    volumeCumulative += item.volume;
    tradesCumulative += item.trades;
    tradedReferralsCountCumulative += item.tradedReferralsCount;
    registeredReferralsCountCumulative += item.registeredReferralsCount;
    totalRebateUsdCumulative += item.totalRebateUsd;
    discountUsdCumulative += item.discountUsd;
    rewardUsdCumulative += item.rewardUsd;

    volumeCumulativeV1 += item.volumeV1;
    tradesCumulativeV1 += item.tradesV1;
    totalRebateUsdCumulativeV1 += item.totalRebateUsdV1;
    discountUsdCumulativeV1 += item.discountUsdV1;
    rewardUsdCumulativeV1 += item.rewardUsdV1;

    volumeCumulativeV2 += item.volumeV2;
    tradesCumulativeV2 += item.tradesV2;
    totalRebateUsdCumulativeV2 += item.totalRebateUsdV2;
    discountUsdCumulativeV2 += item.discountUsdV2;
    rewardUsdCumulativeV2 += item.rewardUsdV2;

    return {
      ...item,
      registeredReferralsCumulative: [...registeredReferralsCumulative],
      registeredReferralsCountCumulativeUnique:
        registeredReferralsCountCumulativeUnique,
      registeredReferralsCountCumulative: registeredReferralsCountCumulative,
      volumeCumulative: volumeCumulative,
      tradesCumulative: tradesCumulative,
      tradedReferralsCountCumulative: tradedReferralsCountCumulative,
      totalRebateUsdCumulative: totalRebateUsdCumulative,
      discountUsdCumulative: discountUsdCumulative,
      rewardUsdCumulative: rewardUsdCumulative,
      volumeCumulativeV1: volumeCumulativeV1,
      tradesCumulativeV1: tradesCumulativeV1,
      totalRebateUsdCumulativeV1: totalRebateUsdCumulativeV1,
      discountUsdCumulativeV1: discountUsdCumulativeV1,
      rewardUsdCumulativeV1: rewardUsdCumulativeV1,
      volumeCumulativeV2: volumeCumulativeV2,
      tradesCumulativeV2: tradesCumulativeV2,
      totalRebateUsdCumulativeV2: totalRebateUsdCumulativeV2,
      discountUsdCumulativeV2: discountUsdCumulativeV2,
      rewardUsdCumulativeV2: rewardUsdCumulativeV2,
    };
  });

  const formattedDataMonthly = aggregateDataByMonth(
    formattedDataWithCumulative,
    [
      "volume",
      "volumeCumulative",
      "trades",
      "tradesCumulative",
      "tradedReferralsCount",
      "tradedReferralsCountCumulative",
      "registeredReferralsCount",
      "registeredReferralsCountCumulative",
      "registeredReferralsCountCumulativeUnique",
      "totalRebateUsd",
      "totalRebateUsdCumulative",
      "discountUsd",
      "discountUsdCumulative",
      "rewardUsd",
      "rewardUsdCumulative",
      "volumeV1",
      "volumeCumulativeV1",
      "tradesV1",
      "tradesCumulativeV1",
      "totalRebateUsdV1",
      "totalRebateUsdCumulativeV1",
      "discountUsdV1",
      "discountUsdCumulativeV1",
      "rewardUsdV1",
      "rewardUsdCumulativeV1",
      "volumeV2",
      "volumeCumulativeV2",
      "tradesV2",
      "tradesCumulativeV2",
      "totalRebateUsdV2",
      "totalRebateUsdCumulativeV2",
      "discountUsdV2",
      "discountUsdCumulativeV2",
      "rewardUsdV2",
      "rewardUsdCumulativeV2",
    ]
  );

  const formattedDataWeekly = aggregateDataByWeek(formattedDataWithCumulative, [
    "volume",
    "volumeCumulative",
    "trades",
    "tradesCumulative",
    "tradedReferralsCount",
    "tradedReferralsCountCumulative",
    "registeredReferralsCount",
    "registeredReferralsCountCumulative",
    "registeredReferralsCountCumulativeUnique",
    "totalRebateUsd",
    "totalRebateUsdCumulative",
    "discountUsd",
    "discountUsdCumulative",
    "rewardUsd",
    "rewardUsdCumulative",
    "volumeV1",
    "volumeCumulativeV1",
    "tradesV1",
    "tradesCumulativeV1",
    "totalRebateUsdV1",
    "totalRebateUsdCumulativeV1",
    "discountUsdV1",
    "discountUsdCumulativeV1",
    "rewardUsdV1",
    "rewardUsdCumulativeV1",
    "volumeV2",
    "volumeCumulativeV2",
    "tradesV2",
    "tradesCumulativeV2",
    "totalRebateUsdV2",
    "totalRebateUsdCumulativeV2",
    "discountUsdV2",
    "discountUsdCumulativeV2",
    "rewardUsdV2",
    "rewardUsdCumulativeV2",
  ]);

  return [
    formattedDataWithCumulative,
    formattedDataWeekly,
    formattedDataMonthly,
  ];
}

export function hexToUtf8(hex) {
  if (hex.length % 2 !== 0) {
    throw new Error("Hex string must have an even length");
  }
  const bytes = [];
  for (let i = 0; i < hex.length; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16));
  }
  const utf8String = new TextDecoder("utf-8").decode(new Uint8Array(bytes));

  return utf8String;
}

export function utf8ToHex(str) {
  const bytes = new TextEncoder().encode(str);
  let hex = Array.from(bytes, (byte) =>
    byte.toString(16).padStart(2, "0")
  ).join("");
  hex = hex.padEnd(64, "0");
  if (hex.length > 64) {
    hex = hex.substring(0, 64);
  }

  return "0x" + hex;
}

export function isValidEthereumAddress(address) {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

const computeDelta = (current, previous) =>
  current && previous ? current - previous : null;

const computePercentage = (current, previous) =>
  current && previous ? ((current - previous) * 100) / previous : null;

export function calculateStats(referralsData) {
  if (!referralsData || referralsData.length === 0) return null;

  const last = referralsData[referralsData.length - 1];
  const secondLast =
    referralsData.length > 1 ? referralsData[referralsData.length - 2] : null;

  return {
    totalVolume: last.volumeCumulative,
    totalVolumeDelta: computeDelta(
      last.volumeCumulative,
      secondLast?.volumeCumulative
    ),
    totalVolumeDeltaPercentage: parseFloat(
      computePercentage(
        last.volumeCumulative,
        secondLast?.volumeCumulative
      ).toFixed(2)
    ),
    totalDiscountUsd: last.discountUsdCumulative,
    totalDiscountUsdDelta: computeDelta(
      last.discountUsdCumulative,
      secondLast?.discountUsdCumulative
    ),
    totalDiscountUsdDeltaPercentage: parseFloat(
      computePercentage(
        last.discountUsdCumulative,
        secondLast?.discountUsdCumulative
      ).toFixed(2)
    ),
    totalAffiliateRebateUsd: last.affiliateRebateUsdCumulative,
    totalAffiliateRebateUsdDelta: computeDelta(
      last.affiliateRebateUsdCumulative,
      secondLast?.affiliateRebateUsdCumulative
    ),
    totalAffiliateRebateUsdDeltaPercentage: parseFloat(
      computePercentage(
        last.affiliateRebateUsdCumulative,
        secondLast?.affiliateRebateUsdCumulative
      ).toFixed(2)
    ),
    totaltradesCount: last.tradesCumulative,
    totaltradesCountDelta: computeDelta(
      last.tradesCumulative,
      secondLast?.tradesCumulative
    ),
    totalTradesCountDeltaPercentage: parseFloat(
      computePercentage(
        last.tradesCumulative,
        secondLast?.tradesCumulative
      ).toFixed(2)
    ),
    totalReferralsCount: last.referralsCountCumulative,
    totalReferralsCountDelta: computeDelta(
      last.referralsCountCumulative,
      secondLast?.referralsCountCumulative
    ),
    totalReferralsDeltaPercentage: parseFloat(
      computePercentage(
        last.referralsCountCumulative,
        secondLast?.referralsCountCumulative
      ).toFixed(2)
    ),
    totalAffiliatesCount: last.referralCodesCountCumulative,
    totalAffiliatesCountDelta: computeDelta(
      last.referralCodesCountCumulative,
      secondLast?.referralCodesCountCumulative
    ),
    totalAffiliatesCountDeltaPercentage: parseFloat(
      computePercentage(
        last.referralCodesCountCumulative,
        secondLast?.referralCodesCountCumulative
      ).toFixed(2)
    ),
  };
}

export function calculateAffiliateGlobalStats(affiliateStat) {
  if (!affiliateStat || affiliateStat.length === 0) {
    return null;
  }

  const last = affiliateStat[affiliateStat.length - 1];
  const secondLast =
    affiliateStat.length > 1 ? affiliateStat[affiliateStat.length - 2] : null;

  return {
    length: affiliateStat.length,
    totalVolume: last.volumeCumulative,
    totalVolumeV1: last.volumeCumulativeV1,
    totalVolumeV2: last.volumeCumulativeV2,
    totalVolumeDelta: computeDelta(
      last.volumeCumulative,
      secondLast?.volumeCumulative
    ),
    totalTrades: last.tradesCumulative,
    totalTradesV1: last.tradesCumulativeV1,
    totalTradesV2: last.tradesCumulativeV2,
    totalTradesDelta: computeDelta(
      last.tradesCumulative,
      secondLast?.tradesCumulative
    ),
    totalReferrals: last.registeredReferralsCountCumulativeUnique,
    totalReferralsDelta: computeDelta(
      last.registeredReferralsCountCumulativeUnique,
      secondLast?.registeredReferralsCountCumulativeUnique
    ),
    totalDiscountUsd: last.discountUsdCumulative,
    totalDiscountUsdDelta: computeDelta(
      last.discountUsdCumulative,
      secondLast?.discountUsdCumulative
    ),
    totalRewardUsd: last.rewardUsdCumulative,
    totalRewardV1: last.rewardUsdCumulativeV1,
    totalRewardV2: last.rewardUsdCumulativeV2,
    totalRewardUsdDelta: computeDelta(
      last.rewardUsdCumulative,
      secondLast?.rewardUsdCumulative
    ),
  };
}

export { sleep, toReadable, createHttpError };
