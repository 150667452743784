import React from "react";

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { formatNumber, COLORS } from "../helpers";

const CustomTooltipTopAffiliates = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p>Volume: {formatNumber(data.x, { currency: true, compact: true })}</p>
        <p>Trades: {formatNumber(data.y * 1e3)}</p>
        <p>
          Referrals: {formatNumber(data.z, { currency: false, compact: false })}
        </p>
      </div>
    );
  }
  return null;
};

const CustomTooltipTopReferrals = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p>Volume: {formatNumber(data.x, { currency: true, compact: true })}</p>
        <p>
          Discount:{" "}
          {formatNumber(data.y * 1e3, { currency: true, compact: true })}
        </p>
        <p>
          <p>Referral: {data.referral}</p>
        </p>
      </div>
    );
  }
  return null;
};

const BubbleChartTopAffiliates = ({ data }) => {
  const domain = [0, Math.max(...data.map((a) => a.tradedReferralsCount))];
  const range = [5, 5000]; // Adjust range for bubble sizes
  const formattedData = data.map((affiliate, index) => ({
    x: affiliate.volumeV1 + affiliate.volumeV2,
    y: affiliate.tradesV1 / 1e3 + affiliate.tradesV2 / 1e3,
    z: affiliate.tradedReferralsCount,
    name: affiliate.referralCode,
  }));

  return (
    <ResponsiveContainer>
      <ScatterChart>
        <XAxis
          type="number"
          dataKey="x"
          name="Volume"
          domain={[0, "dataMax + 700000000"]} // Example of manually setting the max value
          tickFormatter={(tick) =>
            formatNumber(tick, { currency: true, compact: true })
          }
          label={{
            value: "Volume",
            position: "insideBottomRight",
            offset: -5,
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Trades"
          scale="log" // Set to "log" for logarithmic scale
          domain={[0.1, 120]} // Adjust this domain as needed
          tickFormatter={(value) => value.toFixed(0)}
          label={{
            value: "Trades (x 1000)",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <ZAxis
          type="number"
          dataKey="z"
          range={range}
          domain={domain}
          label={null}
        />
        <Scatter data={formattedData} animationDuration={1000}>
          <LabelList
            dataKey="name"
            position="right"
            style={{ fontSize: "10px" }}
          />
          {formattedData.map((entry, index) => (
            <Cell
              key={`bubble-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Scatter>

        <Tooltip
          className="bubble-chart-tooltip"
          content={<CustomTooltipTopAffiliates />}
        />
        <Legend payload={[]} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const BubbleChartTopReferrals = ({ data }) => {
  const formattedData = data.map((referral, index) => ({
    x: referral.volumeV1 + referral.volumeV2,
    y: (referral.discountUsdV1 + referral.discountUsdV2) / 1e3,
    z: 10,
    referralCode: referral.referralCode,
    referral: referral.referral,
  }));

  return (
    <ResponsiveContainer>
      <ScatterChart>
        <XAxis
          type="number"
          dataKey="x"
          name="Volume"
          domain={[0, "dataMax + 300000000"]} // Example of manually setting the max value
          tickFormatter={(tick) =>
            formatNumber(tick, { currency: true, compact: true })
          }
          label={{
            value: "Volume",
            position: "insideBottomRight",
            offset: -5,
          }}
        />
        <YAxis
          type="number"
          dataKey="y"
          name="Discount"
          scale="log"
          domain={[15, "auto"]} // Adjust the domain for logarithmic scale
          tickFormatter={(value) => value.toFixed(0)}
          label={{
            value: "Discount (x 1000$)",
            angle: -90, // Set the angle to -90 for a vertical label
            position: "left", // Adjust the position to insideLeft for better visibility
            offset: -10, // You can adjust the offset as needed
            style: { textAnchor: "middle" }, // Style to anchor the text correctly
          }}
        />
        <ZAxis type="number" dataKey="z" label={null} />
        <Scatter data={formattedData} animationDuration={1000}>
          <LabelList
            dataKey="referralCode"
            position="right"
            style={{ fontSize: "10px" }}
          />
          {formattedData.map((entry, index) => (
            <Cell
              key={`bubble-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Scatter>
        <Tooltip
          className="bubble-chart-tooltip"
          content={<CustomTooltipTopReferrals />}
        />
        <Legend payload={[]} />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export const MemoizedBubbleChartTopAffiliates = React.memo(
  BubbleChartTopAffiliates
);
export const MemoizedBubbleChartTopReferrals = React.memo(
  BubbleChartTopReferrals
);
