import { formatNumber, COLORS } from "../helpers";
import { MdRotateRight } from "react-icons/md"; // Import the loading icon

const dummyData = Array.from({ length: 15 }, (_, index) => ({
  rank: index + 1,
  code: "XXXXXXXXX",
  referrals: 0,
  volume: "$",
  trades: "",
  reward: "$",
}));

export const TablePlaceholder = () => (
  <div className="referral-table referral-table-placeholder">
    <table>
      <thead>
        <tr>
          <th>-</th>
          <th>-</th>
          <th>-</th>
          <th>-</th>
          <th>-</th>
          <th>-</th>
        </tr>
      </thead>
      <tbody>
        {dummyData.map((row, index) => (
          <tr key={index}>
            <td>{row.rank}</td>
            <td>{row.code}</td>
            <td>{row.referrals}</td>
            <td>
              <TableBarChart
                label1="v1"
                value1={0}
                label2="v2"
                value2={0}
                color1={COLORS[13]}
                color2={COLORS[3]}
              />
            </td>
            <td>
              <TableBarChart
                label1="v1"
                value1={0}
                label2="v2"
                value2={0}
                color1={COLORS[13]}
                color2={COLORS[3]}
              />
            </td>
            <td>
              <TableBarChart
                label1="v1"
                value1={0}
                label2="v2"
                value2={0}
                color1={COLORS[13]}
                color2={COLORS[3]}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export const TableBarChart = ({
  value1,
  value2,
  color1,
  color2,
  loading = false,
}) => {
  if (loading) {
    return <MdRotateRight size="2em" className="loader" />;
  }
  const total = value1 + value2;

  return (
    <div className="bar-cell">
      <div className="total">{formatNumber(total, { compact: true })}</div>
      <div className="bar">
        <div
          className="bar-segment"
          style={{
            flex: `${(value1 / total) * 100}%`,
            backgroundColor: color1,
            fontWeight: "bold",
          }}
        >
          v1
        </div>
        {value2 > 0 && (
          <>
            <div className="bar-padding"></div>
            <div
              className="bar-segment"
              style={{
                flex: `${(value2 / total) * 100}%`,
                backgroundColor: color2,
                fontWeight: "bold",
              }}
            >
              v2
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const V1V2Bar = ({
  name,
  label1,
  label2,
  value1,
  value2,
  color1,
  color2,
  currency = false,
  compact = false,
  loading = false,
}) => {
  if (loading) {
    return <MdRotateRight size="2em" className="loader" />;
  }
  const v1 = value1 - value2;
  const v2 = value2;
  const total = v1 + v2;
  const width1 = (v1 / total) * 100;
  const width2 = (v2 / total) * 100;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ textAlign: "center" }}>{name}</div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {v1 > 0 && (
          <div
            style={{
              flex: `0 1 ${width1}%`,
              textAlign: "left",
            }}
          >
            {formatNumber(v1, {
              currency: currency,
              compact: compact,
            })}
          </div>
        )}

        {v2 > 0 && (
          <div
            style={{
              flex: `0 1 ${width2}%`,
              textAlign: "right",
            }}
          >
            {formatNumber(v2, {
              currency: currency,
              compact: compact,
            })}
          </div>
        )}
      </div>

      {/* The bar segments */}
      <div style={{ display: "flex" }}>
        {v1 > 0 && (
          <div
            className="bar-segment"
            style={{
              flex: `${width1}%`,
              backgroundColor: color1,
              fontWeight: "bold",
            }}
          >
            {label1}
          </div>
        )}
        {v2 > 0 && (
          <div
            className="bar-segment"
            style={{
              flex: `${width2}%`,
              backgroundColor: color2,
              fontWeight: "bold",
            }}
          >
            {label2}
          </div>
        )}
      </div>
    </div>
  );
};
